@tailwind base;
@tailwind components;
@tailwind utilities;

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

@layer base {
  :root {
    --background: 0 0% 100%;
    --foreground: 222.2 47.4% 11.2%;
 
    --muted: 17 4% 78.1%;
    --muted-foreground: 0 0% 0%;

    --white: 0 0% 100%;
    --white-foreground: 0 0% 0%;

    --black: 0 0% 0%;
    --black-foreground: 0 0% 100%;
 
    --popover: 0 0% 100%;
    --popover-foreground: 222.2 47.4% 11.2%;
 
    --border: 214.3 31.8% 91.4%;
    --input: 214.3 31.8% 91.4%;
 
    --card: 0 0% 100%;
    --card-foreground: 222.2 47.4% 11.2%;
 
    --primary: 132 22% 35%;
    --primary-foreground: 0 0% 100%;
 
    --secondary: 43 75% 81%;
    --secondary-foreground: 0 0% 0%;
 
    --accent: 355 72% 35%;
    --accent-foreground: 0 0% 100%;
 
    --destructive: 357 77% 52%;
    --destructive-foreground: 0 0% 100%;
 
    --ring: 215 20.2% 65.1%;
 
    --radius: 0.5rem;
  }
}

/* @layer base {
  * {
    @apply border-border;
  }
  body {
    @apply bg-background text-foreground;
    font-feature-settings: "rlig" 1, "calt" 1;
  }
} */